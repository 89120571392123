import { DEFAULT_LANGUAGE as LANG } from './feature-flags';
/**
 * ************************************************
 * Time & Language Property
 * ************************************************
 */
import dayjs from 'dayjs';
import 'dayjs/locale/id';
import 'dayjs/locale/en';

/**
 * ************************************************
 * Flag to to set Bride to BOY first
 * ************************************************
 */
export const IS_BOY_FIRST = true;

/**
 * ************************************************
 * Bride & Groom Info
 * ************************************************
 */
export const GIRL_NAME = 'Fransisca Livinna Bunardi';
export const GIRL_NAME_SHORT = 'Livinna';
export const GIRL_FATHER_NAME = `Alvin Buno Yunardi`;
export const GIRL_MOTHER_NAME = `Rosalina Tjahjadi`;
export const GIRL_PARENT_NAME = 
  LANG === 'en'
    ? `First Daughter of Mr. ${GIRL_FATHER_NAME} <br />& Mrs. ${GIRL_MOTHER_NAME}`
    : `Putri dari Bapak ${GIRL_FATHER_NAME} & Ibu ${GIRL_MOTHER_NAME}`
;
// -> boy section
export const BOY_NAME = 'Kelvin Senjaya';
export const BOY_NAME_SHORT = 'Kelvin';
export const BOY_FATHER_NAME = `Liang Kok Tjing`;
export const BOY_MOTHER_NAME = `Upa Suyatna`;
export const BOY_PARENT_NAME = 
  LANG === 'en'
    ? `Son of Mr. ${BOY_FATHER_NAME} <br />& Mrs. ${BOY_MOTHER_NAME}`
    : `Putra dari Bapak ${BOY_FATHER_NAME} & Ibu ${BOY_MOTHER_NAME}`
;
// -> bride section
export const THE_BRIDE = IS_BOY_FIRST
  ? `${BOY_NAME_SHORT} & ${GIRL_NAME_SHORT}`
  : `${GIRL_NAME_SHORT} & ${BOY_NAME_SHORT}`;

/**
 * ************************************************
 * Instagram Profile Account
 * @important please put instagram id without `@`
 * ************************************************
 */
export const IG_BOY = 'kelvin.senjaya';
export const IG_GIRL = 'fransiscalivinna';
export const IG_FILTER = `https://www.instagram.com/ar/298319269521646/`;

/**
 * ************************************************
 * SEO Requirement
 * @important - Don't forget to update SEO IMAGE
 * ************************************************
 */
export const SEO_IMAGE = `https://ik.imagekit.io/bxwgcojbe/kelvin-livinna/seo.jpg?updatedAt=1691689029772`;
export const SEO_URL = 'https://invitato.net/';
export const SEO_TITLE = `The Wedding of ${THE_BRIDE} by Invitato`;
export const SEO_DESCRIPTION =
  LANG === 'en'
    ? `Together with joyful hearts, we re pleased to announce the beginning of this new chapter of our lives together. Please click the Website Invitation link on this message for more information about our wedding details.`
    : `Menjadi sebuah kebahagiaan bagi kami untuk mengumumkan awal dari babak baru kehidupan kami bersama. Silakan klik tautan situs Undangan Website di bawah untuk informasi lebih lanjut:`;

/**
 * ************************************************
 * Time requirement for Counting Down, and
 * Remind me generator
 * @important - please convert time to Epoch time by
 * using this link https://www.epochconverter.com/
 * ************************************************
 */
export const EPOCH_START_EVENT = 1694844000;
export const EPOCH_RESEPSI_START = 1694844000;
export const EPOCH_END_EVENT = 1694872800;

/**
 * ************************************************
 * DATE REQUIREMENT
 * @important - Format date YYYY-MM-DD
 * ************************************************
 */
export const DATE_AKAD = new Date('2023-09-16');
export const DATE_RESEPSI = DATE_AKAD;

export const WEDDING_AKAD_TIME = '13.00 WIB';
export const WEDDING_AKAD_LOC_NAME = 'Gereja Katolik Santo Andreas';
export const WEDDING_AKAD_LOC_ROAD = `Jl. Komp. Green Garden No. 1, RT 01 RW 04, Kedoya Utara, Kec. Kb. Jeruk, Kota Jakarta Barat, DKI Jakarta 11520`;
export const WEDDING_AKAD_DRESSCODE = `Formal`;
export const WEDDING_AKAD_FULLDATE = dayjs(DATE_AKAD)
  .locale(LANG)
  .format('dddd, DD MMMM YYYY');

export const WEDDING_RESEPSI_TIME = '18.30 WIB';
export const WEDDING_RESEPSI_LOC_NAME = `ALILA SCBD HOTEL, SOUTH JAKARTA`;
export const WEDDING_RESEPSI_LOC_ROAD = `SCBD Lot Jl. Jend. Sudirman Kav 52-53 No. 11, Senayan, Kec. Kby. Baru, Kota Jakarta Selatan, DKI Jakarta 12190`;
export const WEDDING_RESEPSI_DRESSCODE = `Formal`;
export const WEDDING_RESEPSI_FULLDATE = dayjs(DATE_RESEPSI)
  .locale(LANG)
  .format('dddd, DD MMMM YYYY');

  export const WEDDING_RESEPSI_DATE = dayjs(DATE_RESEPSI)
  .locale(LANG)
  .format('DD • MM • YYYY');

/**
 * ********************************************************
 * Link Generator V2
 * @important - this info will be applied at link generator
 * ********************************************************
 */
export const HOSTNAME = 'https://kelvinliviwedding.com';
export const BROADCAST_WEDDING_LOCATION = `ALILA SCBD HOTEL, SOUTH JAKARTA`;
export const BROADCAST_WEDDING_DAY = {
  id: dayjs(DATE_RESEPSI).locale('id').format('dddd, DD MMMM YYYY'),
  en: dayjs(DATE_RESEPSI).locale('en').format('dddd, DD MMMM YYYY'),
};

/**
 * ************************************************
 * Maps Location
 * ************************************************
 */
export const GOOGLE_MAPS_LINK = `https://goo.gl/maps/HNGAxduedQ7t8Egy8`;
export const GOOGLE_MAPS_ADDRESS = `Address details can be seen on the web invitation`;

/**
 * ************************************************
 * Backsound Copyright
 * ************************************************
 */
export const SOUND_BY = `TheOvertunes - I Still Love You (Acoustic Version)`;
export const SOUND_URL = 'https://youtu.be/-LLJ_Ywt1zE';

/**
 * ************************************************
 *  Youtube Live requirement
 * ************************************************
 */
export const YOUTUBE_LINK = 'https://www.youtube.com/live/yZGzTRU1100?feature=share';
export const YOUTUBE_EMBED = 'https://www.youtube.com/embed/yZGzTRU1100';
export const YOUTUBE_PREWEDDING_EMBED = `https://www.youtube.com/embed/hYo2HDyyPnc`;
export const YOUTUBE_PREWEDDING_LINK = `https://youtu.be/hYo2HDyyPnc`;

/**
 * ************************************************
 *  RSVP & Wishlish Section
 * ************************************************
 */
export const API_HOSTNAME = `https://script.google.com/macros/s/AKfycbxs9Agx_LgKBpg4V3zUMOLObLTN7IJfTAV-u8xznOF-xt1mdULOXrBphjXmHSl9xu8FjA/exec`;
export const API_TABLE_NAME = 'wishlist';
export const API_RSVP_TABLE = 'rsvp';
export const SHEET_GIFT_CONFIRMATION = 'gift_confirmation';

/**
 * ************************************************
 *  Invitato Link Requirement
 * ************************************************
 */
export const URL_INVITATO = 'https://invitato.net';
export const URL_IG_INVITATO = 'https://www.instagram.com/dindadipoyono/';
export const URL_WA_INVITATO = 'https://wa.me/+628112778189?text=Hi%20Kak,%20aku%20mau%20pesan%20undangan%20online%20nih!';